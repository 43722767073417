<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client' ||
        getUser.role.slug === 'charge-daffaires'
    "
  >
    <b-card>
      <b-card-header>
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="5"
                lg="5"
              >
                <label for="">Client</label>
                <v-select
                  v-model="payloadFilter.user_id"
                  :options="customerOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(customer) => customer.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isCustomersWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun client ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="6" lg="6">
                            <label for="">Service</label>
                            <v-select
                              id="role"
                              v-model="payloadFilter.service_id"
                              :reduce="(service) => service.id"
                              label="name"
                              :options="recurringServicesOption"
                              class="invoice-filter-select"
                            >
                              <template v-slot:no-options>
                                <b-spinner
                                  v-if="isServicesRecurringWithoutPaginationLoading"
                                  style="width: 2.5rem; height: 2.5rem"
                                  class="align-middle text-info"
                                />

                                <span v-else class="mt-1 font-medium-1"
                                  >Aucun service
                                </span>
                              </template>
                            </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="1"
                lg="1"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterArchivedDirectOrdersAction()"
                >
                  <div v-if="isReLoadFilterData">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataArchivedDirectOrdersAction()"
          >
            <div v-if="isReLoadDataArchivedDirectOrders">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span
              v-else
              class="text-nowrap font-medium-1"
            >Actualiser</span>
          </b-button>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :is-loading="isArchivedDirectOrdersLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span>
              {{ isArchivedDirectOrdersLoading ? "" : "Liste vide" }}
            </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Client -->
          <div
            v-if="props.column.field === 'user'"
            class="align-middle text-center font-small-5"
          >
            <b-media vertical-align="center">
              <span class="d-block text-nowrap text-info font-small-5">
                {{ props.row.package.user.full_name}}
              </span>
              <span class="text-success font-small-4">{{
                props.row.package.user.phone_number
              }}</span>
            </b-media>
          </div>

          <!-- Column: Archivé par -->
          <div
            v-else-if="props.column.field === 'archived_by'"
            vertical-align="center"
            class="align-middle text-center font-small-5"
          >
              <span class="font-small-5 text-info">{{
                props.row.archived_by !== null ? props.row.archived_by.full_name : "Non renseigné"
              }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'archiving_reason'"
            vertical-align="center"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ props.row.archiving_reason }}
            </span>
          </div>

          <!-- Column: Date Archivage -->
          <div
            v-else-if="props.column.field === 'archived_date'"
            class="align-middle text-center font-small-5"
          >
            <b-media vertical-align="center">
              <span class="font-small-5 text-info">{{
                moment(props.row.archived_date)
                  .locale("fr")
                  .format("llll")
                  .split("00:00")[0]
              }}</span>
            </b-media>
          </div>

          <!-- Column: Service -->
          <div
            v-else-if="props.column.field === 'recurring_service'"
            vertical-align="center"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ props.row.recurring_service.name}}
            </span>
          </div>

          <!-- Column: Status -->
          <div
            v-else-if="props.column.field === 'setStatus'"
            class="align-middle text-center font-small-5"
          >
            <b-badge
              pill
              :variant="`light-${resolveOrderStatusVariant(
                props.row.status,
                props.row.placement_cost_paid,
                props.row.search_employee
              )}`"
              class="text-capitalize text-nowrap font-medium-1"
            >
              {{ props.row.setStatus }}
            </b-badge>
          </div>

          <!-- Column: Action -->
          <div
            v-else-if="props.column.field === 'action'"
            class="align-middle text-center"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="25"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :id="`update${props.row.id}`"
                  class="cursor-pointer itemActions"
                  @click="$bvModal.show('modal-archivage'); directOrder = props.row"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="text-success mr-50"
                    size="25"
                  />
                  <span>Editer la raison</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :id="`unarchive${props.row.id}`"
                  class="cursor-pointer itemActions"
                  @click.stop="applyUnarchiveDirectOrderAction(props.row)"
                  v-if="getUser.role.slug === 'responsable-relation-client' ||
                          getUser.role.slug === 'super-administrateur'"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="text-success mr-50"
                    size="25"
                  />
                  <span>Désarchiver la commande</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    getUser.role.slug === 'super-administrateur'
                  "
                  :id="`unarchive${props.row.id}`"
                  class="cursor-pointer itemActions"
                  @click.stop="applyDeleteDirectOrderAction(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger mr-50"
                    size="25"
                  />
                  <span>Supprimer la commande</span>
                </b-dropdown-item>

              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="
            applyLoadMoreArchivedDirectOrderAction(metaData.prev_page_url)
          "
        >
          <span class="text-white text-nowrap font-medium-2">Page précédente</span>
        </b-button>

        <div>
          <span class="text-white font-medium-2">{{ metaData.current_page }} sur
            {{ metaData.last_page }} pages</span>
        </div>
        <div>
          <span class="text-white font-medium-2"
            >Total : {{ total }}</span
          >
        </div>

        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="
            applyLoadMoreArchivedDirectOrderAction(metaData.next_page_url)
          "
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-archivage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Editer la raison de l'archivage"
    >
      <validation-observer
        #default="{}"
        ref="archivingOrderForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyUpdateDirectOrderArchivingReasonAction()"
        >
          <validation-provider
            #default="{ errors }"
            name="archivage"
            rules="required"
          >
            <b-form-group
              label="Raison de l'archivage"
              label-for="archivage"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="archiving-reason"
                v-model="archivingPayload.archiving_reason"
                placeholder="Laissez la raison de l'archivage de cette commande"
                rows="3"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isArchivingLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isArchivingLoading">
                <span>Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Modifier</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-archivage')"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BSpinner,
    BCard,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isReLoadDataArchivedDirectOrders: false,
      tableItems: [],
      isReLoadFilterData: false,
      dir: false,
      customerOptions: [],
      payloadFilter: {
        user_id: '',
        service_id: "",
      },
      archivingPayload: {
        archiving_reason: '',
      },

      rows: [],
      columns: [
        {
          label: 'Client',
          field: 'user',
        },
        {
          label: 'Archivée par',
          field: 'archived_by',
        },
        {
          label: "Date d'archivage",
          field: 'archived_date',
        },
        {
          label: "Raison d'archivage",
          field: 'archiving_reason',
        },
        {
          label: 'Service demandé',
          field: 'recurring_service',
        },
        {
          label: 'Statut',
          field: 'setStatus',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      recurringServicesOption: [],
      avatarText,
      required,
      isTakeOverLoading: false,
      directOrder: null,
      isArchivingLoading: false,
    }
  },

  computed: {
    ...mapGetters('orders', ['getArchivedDirectOrders']),
    ...mapGetters('customers', ['getCustomersWithoutPagination']),

    ...mapGetters(['isArchivedDirectOrdersLoading', 'isCustomersWithoutPaginationLoading', 'isServicesRecurringWithoutPaginationLoading']),
    ...mapGetters('auth', ['getUser']),
     ...mapGetters("services", [
      "getRecurringServices",
      "getRecurringServicesWithoutPagination",
    ]),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
        total: ''
      }
      if (this.getArchivedDirectOrders != null) {
        meta.prev_page_url = this.getArchivedDirectOrders.prev_page_url
        meta.next_page_url = this.getArchivedDirectOrders.next_page_url
        meta.current_page = this.getArchivedDirectOrders.current_page
        meta.last_page = this.getArchivedDirectOrders.last_page
        meta.current_page_url = `${this.getArchivedDirectOrders.path}?page=${this.getArchivedDirectOrders.current_page}`
        this.total = this.getArchivedDirectOrders.total;

      }
      return meta
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  watch: {
    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach((element) => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          });
        });
      }
    },
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
    },
    isArchivedDirectOrdersLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getArchivedDirectOrders.data)
      }
    },
  },

  created() {
    console.log(this.getArchivedDirectOrders)
    // eslint-disable-next-line no-unused-expressions
    this.getArchivedDirectOrders.data != null
      ? (this.rows = this.customTab(this.getArchivedDirectOrders.data))
      : null
  },

  mounted() {
     if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach((element) => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        });
      });
    }
    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach(element => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        })
      })
    }
  },

  methods: {
    ...mapActions('orders', [
      'takeOverAction',
      'getNotCompletedDirectOrdersAction',
      'getArchivedDirectOrdersAction',
      'deleteFailedDirectOrderAction',
      'deleteDirectOrderAction',
      'unarchiveDirectOrderAction',
      'getDirectOrdersAction',
      'loadMoreArchivedDirectOrderAction',
      'filterArchivedDirectOrdersAction',
      'updateDirectOrderArchivingAction',
    ]),
     ...mapActions("packages", [
      "getDirectOrderAction",
      "getDirectPackageOrdersAction",
      "sendFeedbackAction",
      "makePropositionAction",
      "getRecommendedEmployeesAction",
      "getDirectOrderProposedEmployeesAction",
      "getRecommendationEmployeesAction",
      "deleteDirectOrderPropositionAction",
      "getSalaryDetailsAction",
    ]),
    applyFilterArchivedDirectOrdersAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterData = true
      this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', true)
        this.rows = []
        this.filterArchivedDirectOrdersAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
            this.rows = this.customTab(this.getArchivedDirectOrders)
            this.total = this.rows.length
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
             this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        )
          })
      }
    },
    reLoadDataArchivedDirectOrdersAction() {
      this.payloadFilter = {
        user_id: "",
        service_id: "",
      }
      this.isReLoadDataArchivedDirectOrders = true
      this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', true)
      this.rows = []
      this.getArchivedDirectOrdersAction()
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          console.log(response)
          this.isReLoadDataArchivedDirectOrders = false
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isReLoadDataArchivedDirectOrders = false
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
    },

    applyUpdateDirectOrderArchivingReasonAction() {
      this.$refs.archivingOrderForm.validate()
        .then(success => {
          if (success) {
            this.isArchivingLoading = true
            this.updateDirectOrderArchivingAction({ directOrderId: this.directOrder.id, payload: this.archivingPayload })
              .then(response => {
                this.reLoadDataArchivedDirectOrdersAction()
                this.isArchivingLoading = false
                this.$bvModal.hide('modal-archivage')
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: 'Raison mise à jour',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
              .catch(error => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: error,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          }
        })
    },
    resolveOrderStatusVariant: (status, placementCostPaid, search_employee) => {
      if (status === -1) return 'danger'
      if (
        status === 0
        && placementCostPaid === false
        && search_employee === true
      ) {
        return 'danger'
      }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    customTab(array) {
      const tab = []
      array.forEach(element => {
        if (
          element.status === 0
          && element.placement_cost_paid === false
          && element.search_employee === true
        ) {
          element.setStatus = 'Non Payé'
        }
        if (
          element.status === 0
          && element.placement_cost_paid === true
          && element.feedback === ""
        ) {
          element.setStatus = 'En attente de feedback'
        }
         if (
          element.status === 0
          && element.placement_cost_paid === true
          && element.feedback !== ""
        ) {
          element.setStatus = 'En attente de recommandation'
        }
        if (element.status === -1) element.setStatus = 'Résilié'
        if (element.status === 1) element.setStatus = 'Employé proposé'
        if (element.status === 2) element.setStatus = 'Contract émis'
        if (element.status === 3) element.setStatus = 'Contrat approuvé'
        if (element.status === 4) element.setStatus = 'Actif'
        if (element.status === 5) element.setStatus = 'Terminée'

        tab.push(element)
      })
      return tab
    },

    applyLoadMoreArchivedDirectOrderAction(url) {
      this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', true)
      this.rows = []
      this.loadMoreArchivedDirectOrderAction(url)
        .then(() => {
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
    },

    applyTakeOverAction(order) {
      this.isTakeOverLoading = true
      const orderId = order.id
      // console.log(order.id)
      this.$swal({
        title: 'Prise en main',
        text: 'Voulez-vous prendre cette commande en main ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => {
          await this.takeOverAction(orderId)
          return (
            this.applyLoadMoreArchivedDirectOrderAction(
              this.metaData.current_page_url,
            ) && this.getDirectOrdersAction()
          )
        },
      })
        .then(response => {
          if (response.value) {
            this.isTakeOverLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande prise en main avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyUnarchiveDirectOrderAction(order) {
      this.isTakeOverLoading = true
      const orderId = order.id
      this.$swal({
        title: 'Désarchivage',
        text: 'Voulez-vous désarchivager cette commande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.unarchiveDirectOrderAction(orderId)
          return (
            (await this.applyLoadMoreArchivedDirectOrderAction(
              this.metaData.current_page_url,
            )) && (await this.getDirectOrdersAction())
          )
        },
      })
        .then(response => {
          if (response.value) {
            this.isTakeOverLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande désarchivager avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$router.push({name: 'commande',
            params: { id: order.package_id } })
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyDeleteDirectOrderAction(order) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette demande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderAction(order.id)
          return (
            this.applyLoadMoreArchivedDirectOrderAction(
              this.metaData.current_page_url,
            )
          )
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style>
.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
