<template>
  <b-card img-alt="Profile Cover Photo" img-top class="card-profile mt-5">
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
       
        <b-avatar
          size="114"
          variant="dark"
          :text="
            avatarText(
              `${userpackage.user.first_name} ` + ` ${userpackage.user.last_name}`
            )
          "
          :src="userpackage.profile_image"
        />
      </div>
    </div>
    <h3>{{ userpackage.user.last_name }} {{ userpackage.user.first_name }}</h3>

    <h6 class="mt-1 text-info">Résumé des commandes</h6>
    <hr>
    <div v-if="userpackage.terminated_count != 0">
       <span><b-badge class="profile-badge" variant="light-danger">{{userpackage.terminated_count}} Résilié</b-badge></span>
       </div>
       
    <div v-if="userpackage.actifs_count != 0">
    <span class="text-justify"><b-badge class="profile-badge" variant="light-success">{{userpackage.actifs_count}} Actifs</b-badge></span>
    </div>

    <div v-if="userpackage.contracts_approved_count != 0">
    <span><b-badge class="profile-badge" variant="light-info">{{userpackage.contracts_approved_count}} Contrats approuvés</b-badge></span>
    </div>

    <div v-if="userpackage.contracts_issued_count != 0">
    <span><b-badge class="profile-badge" variant="light-dark">{{userpackage.contracts_issued_count}} Contrats  émis</b-badge></span>
    </div >

    <div v-if="userpackage.employee_proposed_count != 0">
    <span><b-badge class="profile-badge" variant="light-white">{{userpackage.employee_proposed_count }} Employés proposés</b-badge></span>
    </div>

    <div v-if="userpackage.wait_propositions_count != 0">
    <span><b-badge class="profile-badge" variant="light-info">{{userpackage.wait_propositions_count}} En attente proposition</b-badge></span>
    </div>

    <div v-if="userpackage.wait_submitted_count != 0">
    <span><b-badge class="profile-badge" variant="light-info">{{userpackage.wait_submitted_count}} En attente soumission</b-badge></span>
    </div>

    <div v-if="userpackage.wait_feedback_count != 0">
    <span><b-badge class="profile-badge" variant="light-warning">{{userpackage.wait_feedback_count}} En attente de feedback</b-badge></span>
    </div>

    <div v-if="userpackage.un_paid_count != 0">
    <span><b-badge class="profile-badge" variant="light-danger">{{userpackage.un_paid_count}} En attente de paiement</b-badge></span>
    </div>
    
    <!-- <h6 class="text-muted">
      <b-badge class="profile-badge" variant="light-success">.</b-badge>
      <b-badge class="profile-badge" variant="light-info">3</b-badge>
      <b-badge class="profile-badge" variant="light-warning">3</b-badge>
      <b-badge class="profile-badge" variant="light-danger">5</b-badge>
    </h6> -->
    <div class="mb-2">
    <span>Total: <span class="text-success">{{userpackage.recurring_orders_count}} commandes</span></span>
    </div>

    <hr>

    <div v-if="getUser.role.slug !== 'charge-daffaires'">
        <div v-if="userpackage.assign_to !== null">
          <h6 class="text-sm" >
            Commercial: {{ userpackage.assign_to.full_name }}
          </h6>
        </div>
        <div v-else>
          <h6 class="text-sm" >
            Commercial: Non assigné
          </h6>
        </div>
    </div>

    <div >
        <div v-if="userpackage.rh !== null">
          <h6 class="text-sm" >
            RH: {{ userpackage.rh.full_name }}
          </h6>
        </div>
        <div v-else>
          <h6 class="text-sm" >
         RH: Non assigné
          </h6>
        </div>
    </div>
    
    <b-button class="mt-1" variant="primary" @click="currentPackageClient">
      Ouvrir
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge, BButton } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { mapGetters } from "vuex";
export default {
  components: {
    BButton,
    BCard,
    BBadge,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    };
  },
  props: {
    userpackage: {
      type: Object,
      default: () => ({}),
    },
  },
  computed : {
    ...mapGetters("auth", ["getUser"]),
    companyFirst(){
      var full_name = this.$props.userpackage.user.company_name
      console.log(this.$props.userpackage)
      var name_parts = full_name.split(" ");
      
      var first_name = name_parts[0];
      console.log(first_name)
      var last_name = name_parts.slice(1).join(" ");

      return first_name
    },
    companyLast(){
      var full_name = this.$props.userpackage.user.company_name
      var name_parts = full_name.split(" ");
      
      var first_name = name_parts[0];
      var last_name = name_parts[1];

      return last_name
    }

  },
  methods: {
    currentPackageClient() {
      this.$store.commit("packages/SET_CURRENT_PACKAGE", this.$props.userpackage);
      // this.$store.commit("packages/SET_CURRENT_CLIENT", this.$props.userpackage.user);
      // localStorage.setItem("client", JSON.stringify(this.$props.userpackage.user));
      localStorage.setItem("clientPackage", JSON.stringify(this.$props.userpackage));
      this.$router.push(`/packages/${this.$props.userpackage.id}/recurring/orders`);
    },
  },
};
</script>
