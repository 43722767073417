<template>
  <div
    v-if="
    getUser.role.slug === 'responsable-relation-client' ||
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'rh' ||
      getUser.role.slug === 'assistant-rh' ||
      getUser.role.slug === 'charge-daffaires'"
  >
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="12"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="10"
                md="10"
                lg="10"
              >
                <label for="service">Client</label>
                <v-select
                  v-model="payloadFilter.client_id"
                  :options="customerOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(client) => client.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isCustomersWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun client ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterCustomersMeetAction()"
                >
                  <div v-if="isFilterEmployeePayment">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="Liste des clients optant pour un entretien avec les employés">
      <b-row>

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataClientAction()"
          >
            <div v-if="isReLoadDataClient">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span
              v-else
              class="text-nowrap font-medium-1"
            >Actualiser</span>
          </b-button>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :is-loading="isCustomersMeetLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isCustomersMeetLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field === 'full_name'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.recurring_order.package.user.full_name
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'employee_full_name'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">
              {{ props.row.employee == null ? 'Non renseigné' : props.row.employee.full_name }}
            </span>
          </div>

          <div
            v-else-if="props.column.field === 'interview_date'"
            class="text-nowrap text-center align-middle"
          >
            <span class="font-small-10 text-info  font-bold">{{ moment(props.row.interview_date).locale("fr").format("llll") }}</span>
          </div>
          <div
            v-else-if="props.column.field === 'status'"
            class="text-nowrap text-center align-middle"
          >
            <span

              v-if="props.row.status == 3"
            >
              <b-badge
                pill
                variant="light-warning"
                class="text-capitalize font-medium-2"
              >En attente d'entretien</b-badge>
            </span>
            <span
              v-else
            >
              <b-badge
                pill
                variant="light-success"
                class="text-capitalize font-medium-2"
              >Entretien passé</b-badge>
            </span>
          </div>

          <div
            v-else-if="props.column.field === 'action'"
            class="align-middle text-center text-info"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'responsable-commercial' || getUser.role.slug === 'responsable-relation-client' || getUser.role.slug === 'charge-daffaires'"
                  :id="`details${props.row.id}`"
                  :disabled="props.row.automatic_pay_status == 2"
                  @click="applyMeetValidation(props.row)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="cursor-pointer text-info mr-1"
                    size="25"
                  />
                  <span>Valider</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'charge-daffaires' "
                  :id="`details${props.row.id}`"
                  v-b-modal.modal-change-meet
                  :disabled="props.row.automatic_pay_status == 2 && props.row.automatic_pay == 0"
                  @click="changeMeetDate(props.row)"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    class="cursor-pointer text-danger mr-1"
                    size="25"
                  />
                  <span>Reprogrammer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreCustomersMeetAction(metaData.prev_page_url)"
        >
          <span class="text-white text-nowrap font-medium-2">Page précédente</span>
        </b-button>

        <div>
          <span
            class="text-white font-medium-2"
          >{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
        </div>
        <div>
          <span class="text-white font-medium-2">Total : {{ total }}</span>
        </div>
        <b-button

          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreCustomersMeetAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>

    <!-- CHANGE CUSTOMER MEETING-->
    <b-modal
      id="modal-change-meet"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      :title="`Changement de la date d'entretien`"
    >
      <validation-observer
        #default="{}"
        ref="changeMeetForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalChangeMeet()"
          @submit.prevent="applyChangeMeetAction()"
        >

          <b-form-group
            label="Nouvelle date d'entretien"
            label-for="date"
          >
            <validation-provider
              #default="{ errors }"
              name="nouvelle date d'entretien"
              rules="required"
            >
              <flat-pickr
                v-model="changeMeetPayload.interview_date"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S'}"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isChangeMeetLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isChangeMeetLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Soumettre</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="hideModalChangeMeet()"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCardHeader,
  BCardBody,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BDropdown,
  BForm,
  BFormGroup,
  BDropdownItem,
  BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    flatPickr,
    VueGoodTable,
  },
  data() {
    return {
      meet_id: '',
      required,
      changeMeetPayload: {
        interview_date: '',
      },
      isChangeMeetLoading: false,
      customerOptions: [],
      isReLoadDataClient: false,
      rows: [],
      employeeOptions: [],
      soldeFilter: null,
      soldeOptions: [
        {
          label: 'Positif',
          value: 'ps',
        },
        {
          label: 'Négatif',
          value: 'nf',
        },
      ],
      payloadFilter: {
        client_id: '',
      },
      columns: [
        {
          label: 'Client',
          field: 'full_name',

        },
        {
          label: 'Employé',
          field: 'employee_full_name',
        },
        {
          label: "Date d'entretien",
          field: 'interview_date',
        },
        {
          label: 'Statut',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],

      isFilterEmployeePayment: false,
      avatarText,

      fields: {
        'Nom complet': 'Nom complet',
        Age: 'Age',
        Téléphone: 'Téléphone',
        'Adresse de résidence': 'Adresse de résidence',
        'Adresse mail': 'Adresse mail',
        'Services fournies': 'Services fournies',
        "Statut de l'employé": "Statut de l'employé",
      },
    }
  },
  computed: {

    ...mapGetters(['isCustomersWithoutPaginationLoading', 'isCustomersMeetLoading']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('customers', ['getCustomersWithoutPagination']),
    ...mapGetters('orders', ['getCustomersMeet']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        current_page_url: '',
        last_page: '',
        total: '',
      }
      if (this.getCustomersMeet != null) {
        meta.prev_page_url = this.getCustomersMeet.prev_page_url
        meta.next_page_url = this.getCustomersMeet.next_page_url
        meta.current_page = this.getCustomersMeet.current_page
        meta.last_page = this.getCustomersMeet.last_page
        this.total = this.getCustomersMeet.total
      }
      return meta
    },
  },

  watch: {
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
    },

    getCustomersMeet(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.getCustomersMeet
      }
    },
    isCustomersMeetLoading(val) {
      if (val === false) {
        this.rows = this.getCustomersMeet
      }
    },

    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },
  },

  mounted() {
    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach(element => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        })
      })
    }
  },

  created() {
    console.log('CUSTOMER MEET', this.getCustomersMeet)
    this.getCustomersMeet != null
      ? (this.rows = this.getCustomersMeet)
      : null
  },

  methods: {

    ...mapActions('orders', ['getCustomersMeetAction', 'meetValidationAction', 'desactivateAutomaticPaymentAction', 'loadMoreCustomersMeetAction', 'filterCustomersMeetAction', 'changeMeetAction']),

    hideModalChangeMeet() {
      this.$bvModal.hide('modal-change-meet')
      this.changeMeetPayload = {
        interview_date: '',

      }
    },

    changeMeetDate(item) {
      console.log(item)
      this.meet_id = item.id
    },

    applyChangeMeetAction() {
      this.$refs.changeMeetForm.validate().then(success => {
        if (success) {
          this.isChangeMeetLoading = true
          console.log(this.meet_id)
          this.changeMeetAction({ meetId: this.meet_id, payload: this.changeMeetPayload })
            .then(response => {
              this.isChangeMeetLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalChangeMeet()
              // this.$bvModal.hide("modal-terminate-employee-contrat");
              this.reLoadDataClientAction()
            })
            .catch(error => {
              this.isChangeMeetLoading = false
              console.log(error)

              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyMeetValidation(meet) {
      this.$swal
        .fire({
          title: "Validation de l'entretien ",
          text: 'Êtes-vous sûr de vouloir valider cet entretien ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: () => this.meetValidationAction(meet.id)
            .then(response => {

            })
            .catch(error => {
              this.$swal.showValidationMessage(`${error.response.data.message}`)
              console.log(error)
            }),
        })
        .then(response => {
          if (response.isConfirmed) {
            console.log(response)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Entretien validé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.reLoadDataClientAction()
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'danger',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDesactivateAutomaticPayment(payment) {
      this.$swal
        .fire({
          title: 'Désactivation de paiement automatique',
          text: 'Êtes-vous sûr de vouloir désactiver ce paiement automatique ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: () => this.desactivateAutomaticPaymentAction(payment.id)
            .then(response => {

            })
            .catch(error => {
              this.$swal.showValidationMessage(`${error.response.data.message}`)
            }),
        })
        .then(response => {
          if (response.isConfirmed) {
            console.log(response)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Paiement automatique désactivé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.reLoadDataClientAction()
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'danger',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyFilterCustomersMeetAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isFilterEmployeePayment = true
        this.rows = []
        this.filterCustomersMeetAction(this.payloadFilter)
          .then(response => {
            this.isFilterEmployeePayment = false
            this.rows = this.getCustomersMeet
          })
          .catch(error => {
            console.log(error)
            this.isFilterEmployeePayment = false
          })
      }
    },
    reLoadDataClientAction() {
      this.isReLoadDataClient = true
      this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', true)
      this.rows = []
      this.payloadFilter = {
        client_id: '',
      }
      this.getCustomersMeetAction()
        .then(response => {
          console.log(this.rows)
          this.isReLoadDataClient = false
          this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataClient = false
          this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', false)
        })
    },

    applyLoadMoreCustomersMeetAction(url) {
      this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', true)
      this.rows = []
      this.loadMoreCustomersMeetAction(url)
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
