<template>
  <div
    v-if="
      getUser.role.slug === 'responsable-relation-client'||
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'superviseur'||
      getUser.role.slug === 'charge-daffaires'
    "
  >
  <b-tabs v-model="tabIndex" pills>
    <b-tab>
      <template #title>
          <feather-icon icon="ShoppingBagIcon" class="text-white mr-1" size="20" /> Commandes récurentes
        </template>
        <hr>
      <div
        v-if="isPageLoading"
        class="d-flex justify-content-center mb-1"
        style="padding-top: 30vh"
      >
        <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
      </div>
      <div v-else >
    <b-card no-body>
      <b-card-header>
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
            <b-row>
              <b-col cols="12" sm="6" md="4" lg="3">
                <label for="">Client</label>
                <v-select
                  v-model="payloadFilter.user_id"
                  :options="customerOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(customer) => customer.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isCustomersWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span v-else class="mt-1 font-medium-1"
                      >Aucun client ne correspond</span
                    >
                  </template>
                </v-select>
              </b-col>
              
              <b-col
                v-if="
                  getUser.role.slug === 'super-administrateur' ||
                  getUser.role.slug === 'responsable-relation-client'
                "
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <label for="">Gestionnaires de packages</label>
                <b-form-select
                  v-if="
                    businessManagerOptions.length === 1 &&
                    businessManagerOptions[0].disabled
                  "
                  id="charge_daffaire_id"
                  :options="businessManagerOptions"
                />
                <v-select
                  v-else
                  id="role"
                  v-model="payloadFilter.ca_id"
                  :reduce="(businessManager) => businessManager.id"
                  label="full_name"
                  :options="businessManagerOptions"
                >
                  <span slot="no-options" @click="$refs.select.open = false">
                    Chargement...
                  </span>
                </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="3" lg="3">
                    <label for="">Status</label>
                    <v-select v-model="payloadFilter.is_closed" :options="statusOptions" class="invoice-filter-select"
                      :reduce="(item) => item.value">
                      <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                      </template>
                    </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                <b-button variant="primary" @click="applyFilterPackagesAction()">
                  <div v-if="isReLoadFilterDataRecurringOrders">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row class="mb-4">
      <b-col cols="12" sm="6" md="3" lg="3">
                    <label for="">Status des commandes</label>
                    <v-select v-model="filtreStatus" :options="filteredStatusOptions" class="invoice-filter-select"
                      :reduce="(item) => item.value">
                      <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                      </template>
                    </v-select>
              </b-col>
    </b-row>
    <b-row>
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-2"
      >
        <b-dropdown
          class="text-nowrap font-medium-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          text="Nouvelle demande"
          variant="primary"
        >
          <b-dropdown-item @click="newCommand">Recherche d'employé</b-dropdown-item>
          <b-dropdown-item @click="newCommandGestion"
            >Confier la gestion d'employé</b-dropdown-item
          >
        </b-dropdown>
      </b-col>

      <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-2">
        <b-row>
          <b-col   v-if="
              getUser.role.slug === 'super-administrateur' ||
              getUser.role.slug === 'responsable-relation-client'

              ">
              <b-button 
              v-if="getUser.role.slug === 'responsable-relation-client'"
              variant="primary" 
              v-b-modal.modal-rapport-suivi
              @click="applyGetUnpublishedSuivis()"
              >
                <div v-if="isRapportSuivi">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>

                <span v-else class="text-nowrap font-medium-1"><feather-icon
                    icon="NavigationIcon"
                    class="text-white mr-1"
                    size="20"
                  />Rapport de suivi</span>
              </b-button>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="reLoadDataRecurringOrdersAction()">
              <div v-if="isReLoadDataRecurringOrders">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else class="text-nowrap font-medium-1">Actualiser</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row> </b-row>

 
    <div
      class="flex items-center text-center justify-center"
      :is-loading="isDirectPackagesLoading"
    >
      <div class="text-center text-info" v-if="isDirectPackagesLoading">
        <b-spinner class="align-middle" /> &nbsp;
        <span class="align-middle">Chargement... </span>
      </div>
      
    </div>
    <!--Debut  Package Card -->
     <div v-if=" isDirectPackagesLoading == false && clientPackage.length == 0" class="text-center ">

      <span class="text-center font-size-18 text-info">Liste vide</span>

    </div>
    <div  v-else>
    <b-row class="match-height">
      <b-col lg="3" md="4" v-for="userpackage in clientPackage" :key="userpackage.id">
        <package-card :userpackage="userpackage" />
      </b-col>
    </b-row>
    </div>
    <!--Fin Package Card -->
 

    <div class="d-flex justify-content-between mt-2 flex-wrap">
      <b-button
        :disabled="metaData.prev_page_url == null"
        variant="outline-secondary"
        @click="applyLoadMoreDirectPackagesAction(metaData.prev_page_url)"
      >
        <span class="text-white text-nowrap font-medium-2">Page précédente</span>
      </b-button>

      <div>
        <span class="text-white font-medium-2"
          >{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span
        >
      </div>
      <div>
        <span class="text-white font-medium-2">Total :{{ total }}</span>
      </div>

      <b-button
        :disabled="metaData.next_page_url == null"
        variant="outline-secondary"
        @click="applyLoadMoreDirectPackagesAction(metaData.next_page_url)"
      >
        <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
      </b-button>
    </div>

    <!-- Order assignment Modal -->
    <b-modal
      id="modal-order-assignment"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Assignation de chargé d'affaire"
    >
      <validation-observer #default="{}" ref="orderAssignmentForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyOrderAssignment"
        >
          <b-form-group label="Liste des chargés d'affaire" label-for="role">
            <validation-provider
              #default="{ errors }"
              name="chargé d'affaires"
              rules="required"
            >
              <b-form-select
                v-if="
                  businessManagerOptions.length === 1 &&
                  businessManagerOptions[0].disabled
                "
                id="charge_daffaire_id"
                v-model="payload.charge_daffaire_id"
                :options="businessManagerOptions"
              />
              <v-select
                v-else
                id="role"
                v-model="payload.charge_daffaire_id"
                :reduce="(businessManager) => businessManager.id"
                label="full_name"
                :options="businessManagerOptions"
              >
                <span slot="no-options" @click="$refs.select.open = false">
                  Chargement...
                </span>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isOrderAssignmentLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isOrderAssignmentLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Assigner</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-order-assignment')"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-action-times"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row class="">
        <b-col md="6" cols="12">
          <p class="card-text mb-25 font-medium-1">
            Date de la commande:
            <span class="text-warning">
              {{ moment(currentOrder.created_at).locale("fr").format("llll") }}
            </span>
          </p>
          <p class="card-text mb-25 font-medium-1">
            Date d'affectation:
            <span class="text-warning">
              {{
                !currentOrder.assign_to
                  ? "Non effectué"
                  : !currentOrder.auto_assignment
                  ? moment(currentOrder.assign_at).locale("fr").format("llll")
                  : "Auto assignation"
              }}
            </span>
          </p>
          <p class="card-text mb-25 font-medium-1">
            Date du feedback:
            <span class="text-warning">{{
              currentOrder.feedback_make_at
                ? moment(currentOrder.feedback_make_at).locale("fr").format("llll")
                : "Non effectué"
            }}</span>
          </p>
          <p class="card-text mb-25 font-medium-1">
            Dernière recommandation:
            <span class="text-warning">
              {{
                currentOrder.recommandation_make_at
                  ? moment(currentOrder.recommandation_make_at)
                      .locale("fr")
                      .format("llll")
                  : "Non effectué"
              }}
            </span>
          </p>
        </b-col>
        <b-col md="6" cols="12">
          <p class="card-text mb-25 font-medium-1">
            Dernière proposition:
            <span class="text-warning">
              {{
                currentOrder.proposition_make_at
                  ? moment(currentOrder.proposition_make_at).locale("fr").format("llll")
                  : "Non effectué"
              }}
            </span>
          </p>
          <p class="card-text mb-25 font-medium-1">
            Date de déploiement:
            <span class="text-warning">{{
              currentOrder.employee_contract_started_date
                ? moment(currentOrder.employee_contract_started_date)
                    .locale("fr")
                    .format("llll")
                : "Non effectué"
            }}</span>
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Deploy Employee Modal -->
    <b-modal
      id="modal-update-deploiement-date"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Date de déploiement d'employé"
    >
      <validation-observer #default="{}" ref="deployEmployeeForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalUpdateDeployEmployeeDate()"
          @submit.prevent="applyUpdateDeploymentEmployee()"
        >
          <b-form-group label="Date de déploiement" label-for="role">
            <validation-provider
              #default="{ errors }"
              name="date de déploiement"
              rules="required"
            >
              <flat-pickr v-model="employee_contract_started_date" class="form-control" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isUpdateDeployementDateLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isUpdateDeployementDateLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Modifier la date</span>
            </b-button>
            <b-button type="reset" variant="outline-secondary"> Annuler </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
     <!-- Rapport de suivi client Modal -->
        <b-modal
          id="modal-rapport-suivi"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          :title="`Rapport du suivi du client`"
          size="xl"
        >
        <div v-if="isDetailSalaryLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
          <div class="justify-content-center" v-else>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col>
                    <span class="text-white"> Suivi client injoignable : </span>
                    <span class="text-info">{{payloadSuivisUnpublished.unreachable}}</span>
                  </b-col>
                  <b-col>
                    <span>
                      <strong class="text-info"></strong>
                    </span>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col>
                    <span class="text-white"> Suivi rien à signaler : </span>
                    <span class="text-info">{{payloadSuivisUnpublished.ras}}</span>
                  </b-col>
                  <b-col>
                    <span>
                      <strong class="text-info">
                       </strong
                      >
                    </span>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col>
                    <span class="text-white">Autres Situation particuliere : </span>
                    <span class="text-info">Voir le tableau ci-dessous</span>
                  </b-col>
                  <b-col>
                    <span>
                      <strong class="text-info"></strong>
                    </span>
                  </b-col>
                </b-row>
                <br />
                <hr />
                <b-row>
                     <!-- List des commentaires -->
          <b-table
            ref="refInvoiceListTable"
            :items="rapports"
            responsive
            :fields="columnsRapport"
            primary-key="id"
            :busy="isRapportSuivi"
            show-empty
            empty-text="Aucune autre situation particulière"
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- Commentaire -->
            <template #cell(client)="data">
              <span> {{ data.item.client.last_name }} </span>
              <span> {{ data.item.client.first_name }} </span>
            </template>
            <!-- Make by -->
            <template #cell(suivis_make_by)="data">
              <span>
                {{ data.item.suivis_make_by.last_name}}
              </span>
               <span>
                {{ data.item.suivis_make_by.first_name}}
              </span>
            </template>
             <!-- Make at -->
            <template #cell(suivis_date)="data">
              <span>
                {{ moment(data.item.suivis_date).locale("fr").format("llll")}}
              </span>
            </template>
          </b-table>
                 
                </b-row>
                <br />
              </b-col>
            </b-row>
            <br />
          </div>
          <hr />

          <div class="d-flex mt-2">
                <b-button
                  :disabled="isSendSuiviRapport"
                  variant="primary"
                  class="mr-2"
                   @click="envoiRapport()"
                >
                  <div v-if="isSendSuiviRapport">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Envoyez Rapport</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalRapport()"
                >
                  Annuler
                </b-button>
              </div>
            
          
        </b-modal>
      </div>
    </b-tab> 
    
    <b-tab>
        <template #title>
          <feather-icon icon="ArchiveIcon" class="text-white mr-1" size="20" /> Commandes archivées
        </template>
        <hr>
        <archived />
      </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="ListIcon" class="text-white mr-1" size="20" /> Liste des entretiens
      </template>
      <hr>
      <meeting />
    </b-tab>
  </b-tabs>
 </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BFormSelect,
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import NotAuthorized from "@/layouts/components/NotAuthorized.vue";
import PackageCard from "@/layouts/components/PackageCard";
import archived from '@/views/order/recurring/Archived.vue'
import meeting from '@/views/order/recurring/CustomerMeet.vue'
export default {
  name: "OrderIndex",
  components: {
    PackageCard,
    BSpinner,
    BTab,
    BTabs,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BFormSelect,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    flatPickr,
    vSelect,
    BDropdown,
    BDropdownItem,
    NotAuthorized,
    archived,
    meeting
  },
  data() {
    return {
      getCurrentPage: "",
      isPageLoading: true,
      isRapportSuivi: false,
       isComments: false,
      rapports: [],
       columnsRapport: [
        {
          key: "client",
          label: "Clients",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "resum",
          label: "Resumé du suivi",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "suivis_make_by",
          label: "Suivi effectué par",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "suivis_date",
          label: "Date ",
          sortable: true,
          class: "font-medium-1",
        },
        
      ],
      isLoading: true,
      clientPackage: [],
      total: "",
      isUpdateDeployementDateLoading: false,
      tableItems: [],
      employee_contract_started_date: "",
      statusOptions: [
        {
          label: "Contrat terminé",
          value: 1,
        },
        {
          label: "Contrat non terminé",
          value: 0,
        },
        
      ],
      statusOrderOptions: [
        {
          label: "En att. assignation",
          value: "PNA",
        },
        {
          label: 'En att. validation exonération',
          value: 'AEF',
        },
        {
          label: "En att. feedback",
          value: "AF",
        },
        {
          label: "En att. proposition",
          value: "AP",
        },
        {
          label: "En att. soumission",
          value: "AS",
        },
        {
          label: "Non Payé",
          value: "NP",
        },
        {
          label: "Employé proposé",
          value: 1,
        },
        {
          label: "Contrat émis",
          value: 2,
        },
        {
          label: "Contrat approuvé",
          value: 3,
        },
        {
          label: "Actif",
          value: 4,
        },
        {
          label: "Terminée",

          value: 5,
        },
        {
          label: "Résilié",
          value: -1,
        },
        
      ],
      isSendSuiviRapport: false,
      payloadSuivisUnpublished :{
        suivi_type: "",
        unreachable: "",
        ras:"",
        others: [],
      },

      managerOptions: [],

      customerOptions: [],
      customerSelect: [],

      payloadFilter: {
        user_id: "",
        ca_id: "",
        direct_order_status: "",
        type: "",
        is_closed:"",
      },
      filtreStatus: "",

      payloadFiltre: {
        filter: ""
      },

      businessManagerOptions: [],

      isOrderAssignmentLoading: false,

      payload: {
        charge_daffaire_id: "",
      },

      orderToAssign: {},
      currentOrder: {},
      required,
      rows1: [],
      rows: [],
      columns: [
        {
          label: "Client",
          field: "user",
          filterOptions: {
            enabled: false,
            placeholder: "Rechercher par Client",
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Service demandé",
          field: "recurring_service",
          filterOptions: {
            enabled: false,
            placeholder: "Rechercher par Service",
            filterFn(data, filterString) {
              return data.name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Status",
          field: "setStatus",
          filterOptions: {
            enabled: false,
            placeholder: "Rechercher par Status",
            filterDropdownItems:[
               "En attente d'assignation",
              "En attente de feedback",
              "En attente de proposition",
              "Résilié",
              "Employé proposé",
              "Non Payé",
              "Contract émis",
              "Contract approuvé",
              "Actif",
              "Terminée",
            ],
            filterFn(data, filterString) {
              return data.toLowerCase() === filterString.toLowerCase();
            },
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      dir: false,
      isReLoadDataRecurringOrders: false,
      isReLoadFilterDataRecurringOrders: false,
    };
  },
  computed: {
    ...mapGetters("packages", ["getDirectPackages"]),
    ...mapGetters("orders", ["getDirectOrders"]),
    ...mapGetters([
      "isDirectOrdersLoading",
      "isDirectPackagesLoading",
      "isDirectPackageOrdersLoading",
      "isDirectOrdersFilterLoading",
      "isCustomersWithoutPaginationLoading",
    ]),

    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("roles", ["getBusinessManagersList"]),
    ...mapGetters("customers", ["getCustomersWithoutPagination"]),

    metaData() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        last_page: "",
        current_page_url: "",
      };
      if (this.getDirectPackages != null) {
        meta.prev_page_url = this.getDirectPackages.prev_page_url;
        meta.next_page_url = this.getDirectPackages.next_page_url;
        meta.current_page = this.getDirectPackages.current_page;
        meta.last_page = this.getDirectPackages.last_page;
        meta.current_page_url = `${this.getDirectPackages.path}?page=${this.getDirectPackages.current_page}`;
        this.total = this.getDirectPackages.total;

        this.getCurrentPage = meta.current_page;
      }
      else{
        
      }
      return meta;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
    
    filteredStatusOptions() {
      if (this.getUser.role.slug === 'charge-daffaires') {
        // Filter the options and exclude the "En att. assignation" option
        return this.statusOrderOptions.filter(option => option.value !== 'PNA');
      }
      // Return all options if the user role is not 'CA'
      return this.statusOrderOptions;
    },
  },
  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
              this.applyGetDirectPackagesAction()
            newValue[key] = "";
            console.log(this.payloadFilter);
          }
        });
      },
      deep: true,
    },

    filtreStatus(val){
      if(val != null){
        this.applyFilterPackagesCommandesAction(val)
      }
      else{
        this.reLoadDataRecurringOrdersAction()
      }
    },

    payloadFiltre: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
              this.applyGetDirectPackagesAction()
            newValue[key] = "";
            console.log(this.payloadFilter);
          }
        });
      },
      deep: true,
    },
    isDirectPackagesLoading(val) {
      if (val === false) {
        this.clientPackage = this.getDirectPackages.data;
      }
    },
   
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach((element) => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          });
        });
      }
    },
  },
  created() {
    console.log("GETCURRENTPAGE", this.getCurrentPage)
    console.log('PPP',this.getDirectPackages)
    this.getDirectPackages.length != 0 ? (this.clientPackage = this.getDirectPackages.data) : this.applyGetDirectPackagesAction();

    this.applyGetDirectPackagesAction()
   
    this.applyGetCommercialListAction()
    // this.getDirectOrders.data.length != 0
    //   (this.rows = this.customTab(this.getDirectOrders.data))
    //   : null

   
  },
  mounted() {
    
    if (
      this.getUser.role.slug === "super-administrateur" ||
      this.getUser.role.slug === "responsable-relation-client"
    ) {
      this.columns.splice(2, 0, {
        label: "Traitée par",
        field: "assign_to",
      });
    }

    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach((element) => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        });
      });
    }
  },
  methods: {
    ...mapActions("roles", ["getBusinessManagersListAction", "getCommercialListAction"]),
    ...mapActions("orders", [
      "assignAnOrderToBusinessManagerAction",
      "changeEmployeeDeploymentAction",
      "getDirectOrdersAction",
      "getDirectOrdersAssignedToBusinessManagerAction",
      "loadMoreDirectOrderAction",
      "filterDirectOrderAction",
    ]),
    ...mapActions("packages", ["getDirectPackagesAction", "filterPackagesAction", "loadMoreDirectPackagesAction","filterPackagesCommandesAction"]),
    ...mapActions("customers", ["getCustomersWithoutPaginationAction"]),
    ...mapActions("suivis", ["getUnpublishedSuivisAction", "sendRapportSuivisAction"]),

    // RAPPORT DE SUIVIS CLIENT
    applyGetUnpublishedSuivis(){
      this.isRapportSuivi = true
       this.payloadSuivisUnpublished.suivi_type = "client"
      this.getUnpublishedSuivisAction(this.payloadSuivisUnpublished)
      .then(response => {
        this.isRapportSuivi = false
         this.payloadSuivisUnpublished = response.data
        this.rapports = response.data.others
        console.log(response)
      })
      .catch(error => {
        this.isRapportSuivi = false
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
      })
    },

    hideModalRapport(){
      this.$bvModal.hide("modal-rapport-suivi");
    },
    envoiRapport(){
      this.isSendSuiviRapport = true
      this.sendRapportSuivisAction()
      .then(response => {
        this.isSendSuiviRapport = false
         this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
       this.hideModalRapport()
      })
      .catch(error => {
        this.isSendSuiviRapport = false
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
      })
    },


    //package liste action
    applyGetDirectPackagesAction() {
      this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
      this.getDirectPackagesAction()
        .then((response) => {
          this.isPageLoading = false
          console.log(response)
          this.clientPackage = response.data.data;
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        })
        .catch(() => {
          this.isPageLoading = false
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        });
    },
    newCommand() {
      this.$router.push(
        "/orders/direct/new/command?search_employee=true&client_hide=false&packageId=null&type=recurring"
      );
      this.$store.commit("SET_IS_PACKAGE_CLICKED", false);
    },
    newCommandGestion() {
      this.$router.push(
        "/orders/direct/new/commandGestion?search_employee=false&client_hide=false&packageId=null"
      );
      this.$store.commit("SET_IS_PACKAGE_CLICKED", false);
    },

    // package(){
    //   this.isLoading = true
    //   this.getDirectPackagesAction()
    //   .then(response =>{
    //     this.isLoading = false
    //     this.clientPackage = response.data
    //     this.toast.success(response.message)
    //   })
    //   .catch(error =>{
    //     this.toast.success(error)
    //   })
    // },

    applyUpdateDeploymentEmployee() {
      this.$bvModal.hide("modal-update-deploiement-date");
      this.isUpdateDeployementDateLoading = true;
      this.$swal
        .fire({
          title: "Modifier la date de déploiement d'employé",
          text: "Êtes-vous sûr de vouloir modifier la date de déploiement  cet employé ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          preConfirm: () =>
            this.changeEmployeeDeploymentAction({
              orderId: this.currentOrder.id,
              payload: { employee_deployment_date: this.employee_contract_started_date },
            })
              .then((response) => {
                this.isUpdateDeployementDateLoading = false;
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Succès",
                      icon: "CheckIcon",
                      text: response.message,
                      variant: "success",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              }),
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.hideModalUpdateDeployEmployeeDate();
            this.getDirectOrdersAction();
          } else {
            this.hideModalUpdateDeployEmployeeDate();
          }
        })
        .catch((error) => {
          this.isUpdateDeployementDateLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    hideModalUpdateDeployEmployeeDate() {
      (this.employee_contract_started_date = ""),
        (this.isUpdateDeployementDateLoading = false);
      this.$bvModal.hide("modal-update-deploiement-date");
    },
    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit("packages/SET_DIRECT_ORDERS", directOrder);
      this.$router.push({
        name: "admin-orders-recurring-details",
        params: { id: directOrder.id },
      });
    },

    reLoadDataRecurringOrdersAction() {
      this.payloadFilter.user_id = "";
      this.payloadFilter.ca_id = "";
      this.payloadFilter.is_closed = "";
      this.payloadFiltre.filter = "";
      this.filtreStatus = null
     

      this.isReLoadDataRecurringOrders = true;
      this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
      this.rows = [];

      this.getDirectPackagesAction()
        .then((response) => {
          this.isReLoadDataRecurringOrders = false;
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        })
        .catch((error) => {
          this.isReLoadDataRecurringOrders = false;
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        });
    },

    applyLoadMoreDirectPackagesAction(url) {
      this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
      this.clientPackage = [];
      this.loadMoreDirectPackagesAction(url)
        .then(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          console.log(this.clientPackage)
        })
        .catch(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        });
    },
    showUpdateDeploiementDateModal(item) {
      console.log(item);
      this.$bvModal.show("modal-update-deploiement-date");
      this.employee_contract_started_date = item.employee_contract_started_date;
      this.currentOrder = item;
    },
    showActionTimesModal(item) {
      this.$bvModal.show("modal-action-times");
      this.currentOrder = item;
    },

    applyGetDirectOrdersAssignedToBusinessManagerAction(id) {
      this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
      this.getDirectOrdersAssignedToBusinessManagerAction(id)
        .then(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        });
    },
    applyGetDirectOrderAction() {
      this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", true);
      this.getDirectOrdersAction()
        .then(() => {
          this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
        });
    },

    applyGetCommercialListAction() {
      this.getCommercialListAction().then((response) => {
        if (response.data.length === 0) {
          this.businessManagerOptions.push({
            value: "",
            disabled: true,
            text: "La liste des chargés d'affaire est vide",
          });
        } else {
          response.data.forEach((element) => {
            this.businessManagerOptions.push(element);
          });
        }
      });
    },

    applyOrderAssignment() {
      this.isOrderAssignmentLoading = true;
      this.assignAnOrderToBusinessManagerAction({
        orderId: this.orderToAssign.id,
        payload: this.payload,
      })
        .then(async (response) => {
          this.applyLoadMoreDirectPackagesAction(this.metaData.current_page_url);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.data.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );
          this.isOrderAssignmentLoading = false;
          this.$bvModal.hide("modal-order-assignment");
          this.payload = {
            charge_daffaire_id: "",
          };
        })
        .catch((error) => {
          this.isOrderAssignmentLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },


    applyFilterPackagesCommandesAction(val) {
      const allIsNUl = Object.values(val).every((el) => el === "");
      const payloadFiltreStatus ={
        filter: val
      }      
      console.log(val)
        if(val != null){
          this.isReLoadDataRecurringOrders = true;
        console.log(val)
        this.filterPackagesCommandesAction(payloadFiltreStatus)
          .then((response) => {
            console.log(response)
           
            if(response.data.length !== 0){
               this.clientPackage = response.data
               this.total = this.clientPackage.length
            }
            else{
                this.clientPackage = []
            }
            this.isReLoadDataRecurringOrders = false;
          })
          .catch((error) => {
            console.log(error);
            this.isReLoadDataRecurringOrders = false;
            this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "danger",
              icon: "AlertTriangleIcon",
              text: error.response.data.message,
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
          });
        }
        else{
          this.reLoadDataRecurringOrdersAction()
        }
        
      
    },


    applyFilterPackagesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
      if (allIsNUl ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
          this.isReLoadFilterDataRecurringOrders = true;
        // this.$store.commit('SET_IS_DIRECT_ORDERS_FILTER_LOADING', true)
        // this.rows = []
        this.payloadFilter.type = "PC"
        console.log(this.payloadFilter.user_id)
        // this.payloadFilter.user_id = this.customerSelect
        this.filterPackagesAction(this.payloadFilter)
          .then((response) => {
            console.log(response)
           
            if(response.data.length !== 0){
               this.clientPackage = response.data
               this.total = this.clientPackage.length
            }
            else{
                this.clientPackage = []
            }
            this.isReLoadFilterDataRecurringOrders = false;
          })
          .catch((error) => {
            console.log(error);
            this.isReLoadFilterDataRecurringOrders = false;
            this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "danger",
              icon: "AlertTriangleIcon",
              text: error.response.data.message,
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
          });
        
      }
    },

  

    openContract(data) {
      this.$router.push({
        path: "/preview/pdf",
        query: {
          makeActionOnPage: false,
          actionType: null,
          isSigned: true,
          pdf: data.contract_file_url,
        },
      });
    },

    sendContractByWhatsApp(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutions!
Merci de cliquer sur le lien suivant afin de consulter le contract de prestation de votre commande ${data.recurring_service.name}. \n\n *${data.contract_file_url}*`);
    },

    sendPlecementFeesPaymentLink(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutations!
                      Merci de cliquer sur le lien suivant afin de payer les frais de placement pour
                      votre demande de *${data.recurring_service.name}*.
                      \n\n https://ylomi.net/direct/${data.id}/placement`);
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return "danger";
      if (status === 0 && placementCostPaid === false && searchEmployee === true) {
        return "danger";
      }
      if (status === 0) return "warning";
      if (status === 1) return "info";
      if (status === 2) return "warning";
      if (status === 3 || status === 4) return "success";
      return "primary";
    },

    customTab(array) {
      const tab = [];
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (
          element.status === 0 &&
          element.placement_cost_paid === false &&
          element.search_employee === true
        ) {
          element.setStatus = "Non Payé";
          tab.push(element);
          continue;
        }

        if (element.status === 0 && element.feedback === null) {
          element.setStatus = "En attente de feedback";
          tab.push(element);
          continue;
        }

        if (
          element.status === 0
          && element.feedback !== ''
          && element.propositions_count === 0
        ) {
          element.setStatus = "En attente de proposition";
          tab.push(element);
          continue;
        }
        if (
          element.status === 0
          && element.feedback != ''
          && element.propositions_count > 0
          && element.submitted_propositions_count === 0
        ) {
          element.setStatus = 'En attente de soumission'
          tab.push(element)
          continue
        }
        if (element.status === -1) {
          element.setStatus = "Résilié";
          tab.push(element);
          continue;
        }
        if (element.status === 1) {
          element.setStatus = "Employé proposé";
          tab.push(element);
          continue;
        }
        if (element.status === 2) {
          element.setStatus = "Contract émis";
          tab.push(element);
          continue;
        }
        if (element.status === 3) {
          element.setStatus = "Contract approuvé";
          tab.push(element);
          continue;
        }
        if (element.status === 4) {
          element.setStatus = "Actif";
          tab.push(element);
          continue;
        }
        if (element.status === 5) {
          element.setStatus = "Terminée";
          tab.push(element);
          continue;
        }
      }
      return tab;
    },
  },
};
</script>

<style>
i.icon-code {
  display: none !important;
}

.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

.itemActions :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 200px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.v-list .v-list-item--active {
  background-color: green !important;
}

.v-list .v-list-item--active .v-list-item__title {
  color: #ffd54f !important;
}
</style>